





















































































































































import { defineComponent, reactive, ref } from '@vue/composition-api';
import { InputBox, LoadingSpinner } from '@/components';
import { authSearvice } from '@/services';

import { REFERENECE_TYPE } from '@/utils';

interface IForm {
  nickName: string;
  email: string;
  profileUrl: string;
  password: string;
  rePassword: string;
  position: string;
  introduction: string;
  referenceUrls: {
    type: REFERENECE_TYPE;
    url: string;
  }[];

  emailValidationCode: string;
}

// eslint-disable-next-line no-useless-escape
const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

export default defineComponent({
  name: 'AuthRegister',
  components: {
    InputBox,
    LoadingSpinner,
  },
  setup(_, { root }) {
    const isEmailValidateConfirmVisible = ref(false);
    const isEmailValidateConfirmed = ref(false);

    const isLoadingEmailValidate = ref(false);
    const isLoadingEmailValidateConfirm = ref(false);
    const isLoadingRegister = ref(false);

    const form = reactive<IForm>({
      nickName: '',
      email: '',
      profileUrl: '',
      password: '',
      rePassword: '',
      position: '',
      introduction: '',
      referenceUrls: [
        {
          type: REFERENECE_TYPE.Github,
          url: '',
        },
        {
          type: REFERENECE_TYPE.Linkedin,
          url: '',
        },
        {
          type: REFERENECE_TYPE.Blog,
          url: '',
        },
      ],
      emailValidationCode: '',
    });

    async function onEmailValidateClicked() {
      if (!form.email) {
        alert('이메일을 입력해 주세요.');
        return;
      }

      if (!regex.test(form.email)) {
        alert('이메일을 확인해 주세요.');
        return;
      }

      if (isLoadingEmailValidate.value) return;

      isLoadingEmailValidate.value = true;

      isEmailValidateConfirmed.value = false;

      try {
        await authSearvice.emailValidate(form.email);

        alert('인증코드가 발송되었습니다.');

        isEmailValidateConfirmVisible.value = true;
      } catch (e) {
        // const error = e as AxiosError;
        // if (error.response?.data.message) {
        //   alert(error.response.data.message);
        // }
      } finally {
        isLoadingEmailValidate.value = false;
      }
    }

    async function onEmailValidateConfirmClicked() {
      if (!form.emailValidationCode) {
        alert('인증코드를 입력해 주세요.');
        return;
      }

      if (isLoadingEmailValidateConfirm.value) return;

      isLoadingEmailValidateConfirm.value = true;

      try {
        await authSearvice.emailValidateConfirm({
          email: form.email,
          code: form.emailValidationCode,
        });

        isEmailValidateConfirmed.value = true;
        isEmailValidateConfirmVisible.value = false;

        form.emailValidationCode = '';
      } catch (e) {
        // const error = e as AxiosError;
        // if (error.response?.data.message) {
        //   alert(error.response.data.message);
        // }
      } finally {
        isLoadingEmailValidateConfirm.value = false;
      }
    }

    async function onSubmitClicked() {
      if (!form.nickName || form.nickName.length < 2) {
        alert('닉네임을 입력해 주세요.');
        return;
      }
      if (!form.email) {
        alert('이메일을 입력해 주세요.');
        return;
      }

      if (!isEmailValidateConfirmed.value) {
        alert('이메일을 인증해 주세요.');
        return;
      }

      if (!regex.test(form.email)) {
        alert('이메일을 확인해 주세요.');
        return;
      }
      if (
        !form.password ||
        form.password.length < 8 ||
        !form.rePassword ||
        form.rePassword.length < 8
      ) {
        alert('비밀번호를 입력해 주세요.');
        return;
      }
      if (form.password !== form.rePassword) {
        alert('비밀번호를 확인해 주세요.');
        return;
      }

      if (isLoadingRegister.value) return;

      isLoadingRegister.value = true;

      try {
        await authSearvice.register({
          nickName: form.nickName,
          profileUrl: form.profileUrl,
          email: form.email,
          password: form.password,
          position: form.position,
          introduction: form.introduction,
          referenceUrls: form.referenceUrls.filter(reference => !!reference.url),
        });

        alert('회원가입 되었습니다.');

        root.$router.push('/');
      } catch (e) {
        // const error = e as AxiosError;
        // if (error.response?.data.message) {
        //   alert(error.response.data.message);
        // }
      } finally {
        isLoadingRegister.value = false;
      }
    }

    return {
      isLoadingRegister,

      form,
      isEmailValidateConfirmVisible,
      isEmailValidateConfirmed,

      onEmailValidateClicked,
      onEmailValidateConfirmClicked,
      onSubmitClicked,
    };
  },
});
